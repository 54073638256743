<template>
  <div class="md-solution-case-nav-card">
    <div class="left">
      <div class="header">
        <div>
          <h1>行业划分</h1>
          <p>根据行业所属划分解决方案，更加直观清晰明确</p>
        </div>
        <img src="~@/assets/solution/bg-solution-left-header.png">
      </div>
      <ul class="content">
        <li v-for="industry in industrys" :key="'industry_' + industry.icon" @click="$router.push(industry.router)">
          <h2>{{ industry.name }}</h2>
          <p>{{ industry.description }}</p>
          <img :src="require('@/assets/solution/icon-industry-'+ industry.icon +'.png')">
        </li>
      </ul>
    </div>
    <div class="right">
      <div class="header">
        <div>
          <h1>痛点划分</h1>
          <p>根据痛点划分解决方案，更垂直解决用户问题</p>
        </div>
        <img src="~@/assets/solution/bg-solution-right-header.png">
      </div>
      <ul class="content">
        <li v-for="item in cases" :key="'case_' + item.image" @click="$router.push(item.router)">
          <p>{{ item.name }}</p>
          <!-- <icon icon="arraw" class="arraw" /> -->
          <div class="picture" :class="'picture-' + item.image">
            <div class="bg-linear-gradient"></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SolutionCaseNavCard',
  data () {
    return {
      industrys: [
        { name: '汽车行业', description: '通过MES解决方案赋能汽车生产，通过数字孪生', icon: 'digital', router: '/carIndustry' },
        { name: '化工行业', description: '化工行业安全生产及风险应急云平台', icon: 'chemical', router: '/chemicalIndustry' },
        { name: '物流行业', description: '通过“互联网+物流”的创新模式，建设全智能化', icon: 'logistics', router: '/logisticsIndustry' },
        { name: '家电行业', description: '家电行业解决方案，依托大数据服务平台和生态', icon: 'household', router: '/homeApplianceIndustry' },
        { name: '设备制造', description: '基于工业互联网平台+自动化装备及流程软件', icon: 'device', router: '/deviceFabrication' },
        { name: '政务服务', description: '以IaaS为核心的云计算中心为政府应用提供有力', icon: 'government', router: '/governmentService' },
        { name: '产业集群', description: '西图盟通过对集群内企业和供应链的持续', icon: 'cluster', router: '/industrialCluster' },
        { name: '行业通用', description: '为离散制造业赋能：降本、创收、辅助客户运营', icon: 'general', router: '/industryGeneral' },
      ],
      cases: [
        { name: '数字孪生虚拟工厂解决方案', image: 'digital', router: '/carIndustry' },
        { name: '多式链路-智慧物流解决方案', image: 'logistics', router: '/logisticsIndustry' },
        { name: '生产设备管理资产解决方案', image: 'device', router: '/deviceFabrication' },
        { name: '精益管理智能工厂解决方案', image: 'factory', router: '/industrialCluster' },
        { name: '工业信用自证区块链解决方案', image: 'blockchain', router: '/industryGeneral' },
      ],
    }
  },
}
</script>

<style lang="less" scoped>
.md-solution-case-nav-card {
  display: flex;
  /* Rectangle 2872 */
  .left {
    width: 52%;
    padding-right: 24px;
    box-sizing: border-box;
    border-right: 1px solid #EDEFF4;
    .header {
      margin-bottom: 16px;
    }
    ul.content {
      display: flex;
      flex-wrap: wrap;
      >li {
        position: relative;
        width: calc(50% - 8px);
        box-sizing: border-box;
        margin-bottom: 8px;
        margin-top: 8px;
        padding: 12px;
        border: 1px solid #EDEFF4;
        cursor: pointer;
        background-size: 32px 32px;
        &:nth-child(2n) {
          margin-left: 8px;
        }
        &:nth-child(2n+1) {
          margin-right: 8px;
        }
        > h2 {
          font-size: 14px;
          color: #1D2129;
          font-weight: 600;
          margin-bottom: 4px;
        }
        > p {
          color: #86909C;
          zoom: 0.86;
        }
        > img {
          position: absolute;
          width: 42px;
          height: 42px;
          right: 18px;
          top: -12px;
        }
        &:hover {
          box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
          >h2 {
            color: #3180F6;
          }
        }
      }
    }
  }
  .right {
    width: 48%;
    padding-left: 24px;
    box-sizing: border-box;
    .header {
      margin-bottom: 24px;
    }
    ul.content {
      > li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #EDEFF4;
        margin-bottom: 8px;
        padding-left: 12px;
        box-sizing: border-box;
        color: #86909C;
        cursor: pointer;
        > p {
          width: 160px;
          &::after {
            position: absolute;
            content: '';
            background: url('~@/assets/solution/icon-arraw.png') no-repeat;
            background-size: 100% 100%;
            width: 12.5px;
            height: 7.5px;
            left: 176px;
            margin-top: 5px;
          }
        }
        .picture {
          flex: 1;
          &.picture-digital {
            background: url('~@/assets/solution/bg-case-digital.png') no-repeat;
            background-size: 100% 100%;
          }
          &.picture-logistics {
            background: url('~@/assets/solution/bg-case-logistics.png') no-repeat;
            background-size: 100% 100%;
          }
          &.picture-factory {
            background: url('~@/assets/solution/bg-case-factory.png') no-repeat;
            background-size: 100% 100%;
          }
          &.picture-device {
            background: url('~@/assets/solution/bg-case-device.png') no-repeat;
            background-size: 100% 100%;
          }
          &.picture-blockchain {
            background: url('~@/assets/solution/bg-case-blockchain.png') no-repeat;
            background-size: 100% 100%;
          }
          .bg-linear-gradient {
            height: 55px;
            background: linear-gradient(90.21deg, #FFFFFF 10.54%, rgba(255, 255, 255, 0.49) 61.1%);
          }
        }
        &:hover {
          > p {
            color: #3180F6;
            &::after {
              position: absolute;
              content: '';
              background: url('~@/assets/solution/icon-arraw-hover.png') no-repeat;
              background-size: 100% 100%;
              width: 32px;
              height: 7.5px;
              left: 176px;
              margin-top: 5px;
            }
          }
          .picture {
            &.picture-digital {
              background: url('~@/assets/solution/bg-case-digital-hover.png') no-repeat;
              background-size: 100% 100%;
            }
            &.picture-logistics {
              background: url('~@/assets/solution/bg-case-logistics-hover.png') no-repeat;
              background-size: 100% 100%;
            }
            &.picture-factory {
              background: url('~@/assets/solution/bg-case-factory-hover.png') no-repeat;
              background-size: 100% 100%;
            }
            &.picture-device {
              background: url('~@/assets/solution/bg-case-device-hover.png') no-repeat;
              background-size: 100% 100%;
            }
            &.picture-blockchain {
              background: url('~@/assets/solution/bg-case-blockchain-hover.png') no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      }
    }
  }
  .left, .right {
    .header {
      display: flex;
      justify-content: space-between;
      h1 {
        margin-bottom: 8px;
        font-size: 18px;
        color: #1D2129;
        font-weight: 600;
      }
      p {
        color: #86909C;
        zoom: 0.96;
      }
      img {
        height: 64px;
        margin-top: -8px;
      }
    }
  }
}
</style>
