<template>
  <div class="md-customer-case-nav-card">
    <div class="left">
      <ul>
        <li v-for="item in industrys" :data-key="item.key" :key="item.key" :selected="currentIndustry === item.key" @mouseenter="mouseoverCases">
          <div class="line"></div>
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>
    <div class="right" >
              <div class="picture" :class="'picture-' + currentCase.image">
            <div class="bg-linear-gradient"></div>
      <h1 class="name">{{ currentCase.name }}</h1>
      <p class="desc">{{ currentCase.description }}</p>
      <div class="right_bottom">
        <div class="chars">
          <label>案例特点：</label>
          <ul>
            <li v-for="char in currentCase.chars" :key="char"><span>{{ char }}</span></li>
          </ul>
        </div>
        <div class="advantages">
          <label>案例优势：</label>
          <ul>
            <li v-for="adv in currentCase.advantages" :key="adv"><span>{{ adv }}</span></li>
          </ul>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomerCaseNavCard',
  data () {
    return {
      industrys: [
        { name: '汽车行业', key: 'carIndustry' },
        { name: '设备制造', key: 'deviceFabrication' },
        { name: '化工行业', key: 'chemicalIndustry' },
        { name: '政务服务', key: 'governmentService' },
        { name: '物流行业', key: 'logisticsIndustry' },
        { name: '产业集群', key: 'industrialCluster' },
        { name: '家电行业', key: 'homeApplianceIndustry' },
        { name: '行业通用', key: 'industryGeneral' },
      ],
      currentIndustry: 'carIndustry',
      industryCaseMap: {
        carIndustry: {
          name: '路特斯',
          description: '通过MES解决方案赋能汽车生产，通过数字孪生技术让车间管理实现数字化。',
          chars: ['智慧物流', '数字孪生', '智能硬件'],
          advantages: ['境外跨国公司', '行业独角兽'],
          image: 'carIndustry',
        },
        deviceFabrication: {
          name: '设备制造行业',
          description: '工业互联网平台+自动化装备及流程软件，新型工业APP支撑的完整解决方案，帮助设备制造企业达成高质量生产',
          chars: ['制造运营管理', '高级计划排程', '设备健康'],
          advantages: ['数据集中共享', '设备集中控制管理'],
          image: 'deviceFabrication',
        },
        chemicalIndustry: {
          name: '化工行业',
          description: '化工行业安全生产及风险应急云平台，实现化工企业的生产一屏统管，安全一屏感知。',
          chars: ['数据收集', '数据追溯', '仓库管理系统'],
          advantages: ['特殊行业', '全产线改造'],
          image: 'chemicalIndustry',
        },
        governmentService: {
          name: '政务服务',
          description: '以IaaS为核心的云计算中心为政府应用提供有力的后台保障，以云平台实现政府部门间信息联动与政务工作协同，信息孤岛向互联互通转变',
          chars: ['PAAS平台', '互联互通', '数据共享'],
          advantages: ['智慧大脑', '统一规划'],
          image: 'governmentService',
        },
        logisticsIndustry: {
          name: '物流行业',
          description: '通过“互联网+物流”的创新模式，建设全智能化物流平台，重塑物流行业格局。',
          chars: ['AI', 'IOT', '智能运维'],
          advantages: ['智慧大脑', '统一规划'],
          image: 'logisticsIndustry',
        },
        industrialCluster: {
          name: '产业集群',
          description: '通过对集群内企业和供应链的持续深入服务，积累并沉淀一批行业共性机理模型，建立该产业集群模型库，并建立集群共建共享机制',
          chars: ['ICT', '信息同步', '整合生产计划'],
          advantages: ['生产与市场强匹配', '贯穿上下游'],
          image: 'industrialCluster',
        },
        homeApplianceIndustry: {
          name: '家电行业',
          description: '依托大数据服务平台和生态应用体系，实现为企业及其上下游企业转型赋能',
          chars: ['系统集成', 'IoT', '数据采集'],
          advantages: ['工业APP开发', '数据互通'],
          image: 'homeApplianceIndustry',
        },
        industryGeneral: {
          name: '行业通用',
          description: '为离散制造业赋能：降本、创收、辅助客户运营',
          chars: ['透明工厂', '云平台', '设备数据'],
          advantages: ['行业通用', '全场景'],
          image: 'industryGeneral',
        },
      },
    }
  },
  computed: {
    currentCase () {
      return this.industryCaseMap[this.currentIndustry] || {}
    },
  },
  methods: {
    mouseoverCases (e) {
      const targetDatakeyAttr = e.target.attributes['data-key']
      this.currentIndustry = targetDatakeyAttr ? targetDatakeyAttr.value : ''
    },
  },

}
</script>

<style lang="less" scoped>
.md-customer-case-nav-card {
  display: flex;
  font-size: 14px;
  .left {
    width: 320px;
    height: 215px;
    padding: 24px 32px;
    box-sizing: border-box;
    background: url('~@/assets/case/bg-customer-case-left.png') no-repeat;
    background-size: cover;

    > ul {
      display: flex;
      flex-wrap: wrap;
      > li {
        width: 50%;
        height: 42px;
        line-height: 42px;
        cursor: pointer;
        > .line {
          display: inline-block;
          height: 2px;
          width: 8px;
          background: #86909C;
          vertical-align: middle;
        }
        > span {
          padding-left: 8px;
          vertical-align: middle;
        }
        &:hover, &[selected] {
          > .line {
            width: 20px;
            background: #3180F6;
          }
          > span {
            color: #3180F6;
            font-size: 18px;
          }
        }
      }
    }
  }
  .right {
    position: relative;
    flex: 1;
    padding: 24px 20px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-size: 60% 100%;
    background-color: #FAFBFE;
    .picture {
      height: 100%;
      width: 100%;
          &.picture-carIndustry {
            background: url('/cases/carIndustry.png') no-repeat;
            background-size: 100% 100%;
          }
          &.picture-deviceFabrication {
            background: url('/cases/deviceFabrication.png') no-repeat;
            background-size: 100% 100%;
          }
          &.picture-chemicalIndustry {
            background:url('/cases/chemicalIndustry.png') no-repeat;
            background-size: 100% 100%;
          }
          &.picture-governmentService {
            background: url('/cases/governmentService.png') no-repeat;
            background-size: 100% 100%;
          }
          &.picture-logisticsIndustry {
            background:url('/cases/logisticsIndustry.png') no-repeat;
            background-size: 100% 100%;
          }
          &.picture-industrialCluster {
            background:url('/cases/industrialCluster.png') no-repeat;
            background-size: 100% 100%;
          }
          &.picture-homeApplianceIndustry {
            background:url('/cases/homeApplianceIndustry.png') no-repeat;
            background-size: 100% 100%;
          }
          &.picture-industryGeneral {
            background:url('/cases/industryGeneral.png') ;
            background-size: 100% 100%;
          }
        }
    .name {
      font-size: 16px;
      color: #1D2129;
      font-weight: 600;
      line-height: 32px;
      margin-bottom: 4px;
    }
    .desc {
      width: 70%;
      color: #86909C;
      line-height: 18px;
      font-size: 12px;
    }
    .right_bottom {
      position: absolute;
      bottom: 32px;
    }
    .chars {
      margin-bottom: 12px;
    }
    .chars, .advantages {
      display: flex;
      align-items: center;
      > label {
        font-size: 12px;
        color: #4E5969;
      }
      > ul {
        flex: 1;
        display: flex;
        align-items: center;
         > li {
          padding: 0 8px 2px;
          color: #1D2129;
          border: 1px solid #EDEFF4;
          &:not(:first-child) {
            margin-left: 8px;
          }
          > span {
            display: inline-block;
            font-size: 12px;
            transform: scale(0.96);
          }
        }
      }
    }
  }
}
</style>
