import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 处理 swiper
import {
  Swiper as SwiperClass,
  Pagination,
  Mousewheel,
  Autoplay,
} from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import './style/reset.less'
// import './style/font/font.less'
import 'swiper/swiper-bundle.css'
// 引入svg组件
import Icon from '@/components/Icon'
import { setDocumentTitle, domTitle } from '@/utils/document'

// 全局注册icon-svg
Vue.component('icon', Icon)

SwiperClass.use([Pagination, Mousewheel, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))

const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  const toMeta = to.meta || {}
  typeof toMeta.title !== 'undefined' && setDocumentTitle(`${domTitle} - ${toMeta.title}`)
  next()
})

new Vue({
  router,
  components: { Swiper, SwiperSlide },
  render: h => h(App),
}).$mount('#app')
