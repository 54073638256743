<template>
  <svg
    class="svg-icon"
    aria-hidden="true"
  >
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>

const requireAll = ctx => ctx.keys().map(ctx)
const req = require.context('@/assets/icons', false, /\.svg$/)
requireAll(req)
export default {
  name: 'icon',
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconName () {
      return `#icon-${this.icon}`
    },
  },
}
</script>

<style>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
