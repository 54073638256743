<template>
  <div class="productServiceNavCardConeten">
    <ul class="md-product-service-nav-card">
    <li style="display: inline-block; width:150px">
      <div class="title">元宇宙工厂</div>
      <div class="sub_nav">
        <ul class="sub_nav_group">
          <li @click="$router.push('/product/getaverse/#vcOne')">数字孪生</li>
          <li @click="$router.push('/product/getaverse/#vcOne')">未来工厂</li>
        </ul>
      </div>
    </li>
    <li style="display: inline-block; width:150px">
      <div class="title">虚拟工厂</div>
      <div class="sub_nav">
        <ul class="sub_nav_group">
          <li @click="$router.push('/product/virtualFactory/#vcOne')">G-iPS工业数字孪生软件</li>
          <li @click="$router.push('/product/virtualFactory/#vcTwo')">数字化工艺仿真</li>
          <li @click="$router.push('/product/virtualFactory/#vcThree')">生产虚拟调试</li>
          <li @click="$router.push('/product/virtualFactory/#vcFour')">虚拟生产验证</li>
        </ul>
      </div>
    </li>
    <li style="display: inline-block; width:150px">
      <div class="title">AI应用</div>
      <div class="sub_nav">
        <ul class="sub_nav_group">
          <li @click="$router.push('/product/AIApplication/#vcOne')">工业视觉AI</li>
          <li @click="$router.push('/product/AIApplication/#vcTwo')">工业数据AI</li>
        </ul>
      </div>
    </li>
    <li style="display: inline-block; width:150px">
      <div class="title">应用系统</div>
      <div class="sub_nav">
        <ul class="sub_nav_group">
          <li @click="$router.push('/product/appSystem/#vcOne')">G-iWCS智能仓储控制系统</li>
          <li @click="$router.push('/product/appSystem/#vcTwo')">G-iEAM设备管理系统</li>
          <li @click="$router.push('/product/appSystem/#vcThree')">G-iEMS能耗管理系统</li>
          <li @click="$router.push('/product/appSystem/#vcFour')">G-iLIMS实验室管理系统</li>
          <li @click="$router.push('/product/appSystem/#vcFive')">G-iAGV 通用AGV调度系统</li>
        </ul>
      </div>
    </li>
    <li style="display: inline-block; width:150px">
      <div class="title">过程管控</div>
      <div class="sub_nav">
        <ul class="sub_nav_group">
          <li  @click="$router.push('/product/processControl/#vcOne')">软安灯呼叫系统</li>
          <li @click="$router.push('/product/processControl/#vcTwo')">精益提案管理系统</li>
          <li @click="$router.push('/product/processControl/#vcThree')">R质量防错眼镜 </li>
          <li @click="$router.push('/product/processControl/#vcFour')">工艺诊断专家系统</li>
        </ul>
      </div>
    </li>
  </ul>
  <ul class="md-product-service-nav-card">
    <li style="display: inline-block; width:150px">
      <div class="title">工艺培训</div>
      <div class="sub_nav">
        <ul class="sub_nav_group">
          <li @click="$router.push('/product/craftTraining/#vcOne')">MR三维互动教学系统</li>
          <li @click="$router.push('/product/craftTraining/#vcTwo')">虚拟增强工艺培训系统</li>
          <li @click="$router.push('/product/craftTraining/#vcThree')">赋能培训课程 </li>
        </ul>
      </div>
    </li>
    <li style="display: inline-block; width:150px">
      <div class="title">G-imom数字化运营</div>
      <div class="sub_nav">
        <ul class="sub_nav_group">
          <li @click="$router.push('/product/gimom/#imom-pms')">PMS 自适应主数据管理</li>
          <li @click="$router.push('/product/gimom/#imom-jis')">JIS 自适应生产管理 </li>
          <li @click="$router.push('/product/gimom/#imom-les')">LES 自适应物流管理 </li>
        </ul>
      </div>
    </li>
    <li style="display: inline-block">
      <div class="title">机器人软件</div>
      <div class="sub_nav">
        <ul class="sub_nav_group">
          <li @click="$router.push('/product/robotSoftware/#vcOne')">G-iCA机器人坐标系标定软件</li>
          <li @click="$router.push('/product/robotSoftware/#vcTwo')">G-iRP机器人软件扩展功能包</li>
          <li @click="$router.push('/product/robotSoftware/#vcThree')">G-iRP机器人软件扩展工艺包</li>
        </ul>
      </div>
    </li>
  </ul>
  </div>
</template>

<script>
export default {
  name: 'ProductServiceNavCard',
  methods: {
    // serviceEvent (type) {
    //   this.$router.push({
    //     path: '/product/virtualFactory',
    //     query: {
    //       type,
    //     },
    //   })
    // },
  },
}
</script>

<style lang="less" scoped>
.productServiceNavCardConeten{
  .md-product-service-nav-card {
    display: inline-block;
  > li {
    &:not(:first-child) {
      margin-left: 32px;
    }
    &:not(:last-child) {
      margin-right: 32px;
    }
  }
  .title {
    height: 48px;
    line-height: 45px;
    margin-bottom: 15px;
    font-size: 14px;
    color: #1D2129;
    font-weight: 600;
    border-bottom: 1.5px solid #3180F6;
  }
  .sub_nav {
    height: 140px;
    display: flex;
    ul.sub_nav_group {
      display: inline-block;;
      > li {
        line-height: 28px;
        color: #4E5969;
        cursor: pointer;
        &:hover {
          color: #3180F6;
        }
      }
    }
  }
}
}

</style>
